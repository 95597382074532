import React from 'react'
import "./ContactHeader.css"

const ContactHeader = () => {
    return (
        <>
            <div className="contact-rig">
                <div className="contact-img">
                    <img src="img/whyus/6.png" alt="" />
                </div>
                <div className="contact-body">
                    <div className="contact-heading">
                        <h1>Contact Us</h1>
                    </div>
                    <div className="contact-body-flex">
                        <div className="contact-content">
                            <img src="img/contact/1.png" alt="" />
                            <h1>Location</h1>
                            <p>93, Urban Sabujayan Complex, Kalikapur, Kolkata 700046</p>
                        </div>
                        <div className="contact-content">
                            <img src="img/contact/2.png" alt="" />
                            <h1>Call Us</h1>
                            <p>+91 8210475883</p>
                            <p>+91 8578980746</p>
                        </div>
                        <div className="contact-content">
                            <img src="img/contact/3.png" alt="" />
                            <h1>Mail Us</h1>
                            <p>contactus@rignsam.com</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-map">
                <div className="contact-map-div">
                    <div className="contact-map-heading"><h1>Location</h1></div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3686.1558978310154!2d88.4161111!3d22.498333299999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x209e7e0ca8ecdc08!2zMjLCsDI5JzU0LjAiTiA4OMKwMjQnNTguMCJF!5e0!3m2!1sen!2sin!4v1675100351124!5m2!1sen!2sin" ></iframe>
                </div>
            </div>
        </>
    )
}

export default ContactHeader