import React,{useEffect} from 'react'
import ContactFormRig from '../AboutUS/ContactForm/ContactFormRig'
import Footer from '../landingPage/Footer/Footer'
import Navbar from '../landingPage/navbar/Navbar'
import AffectUs from './AffectUs/AffectUs'
import WhyDesc from './Description/WhyDesc'
import Whyheader from './Header/Whyheader'
import WhyTestimonials from './WhyTestimonials/WhyTestimonials'
import WhyVi from './WhyVideo/WhyVi'
import WhyVideo from './WhyVideo/WhyVi'

const WhyUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
        <Navbar/>
        <Whyheader/>
        <WhyVi/>
        <WhyDesc/>
        <AffectUs/>
        {/* <WhyTestimonials/> */}
        <ContactFormRig/>
        <Footer/>
    </>
  )
}

export default WhyUs