import React from 'react'
import "./Students.css"
import { Link } from 'react-router-dom'

const Students = () => {
  return (
    <>
        <div className="students-div">
            <div className="students-div-left">
                <h1>Start Learning with us?</h1>
                <p>Start Learning with our live classes, recorded materials, 24x7 doubt clearing chat and many more features and achieve your career goal.</p>
                <a href="https://play.google.com/store/apps/details?id=com.kp.sir.biology.classes" target="_blank">
                    <button>Start Learning</button>
                </a>
            </div>
            <div className="students-div-right">
                <div className="students-content">
                    <img src="img/students/1.png" alt="" />
                    <img src="img/students/2.png" alt="" />
                </div>
                <div className="students-content">
                    <img src="img/students/3.png" alt="" />
                    <img src="img/students/4.png" alt="" />
                </div>
            </div>    
        </div>

        <div className="pic-banner">
            <div className="pic-banner-div followup banner-img1" style={{backgroundColor:"#02E8A1"}}>
                <h1>A Variety of Courses for Everyone</h1>
                <p>LEARN ANYWHERE, LEARN ANYTHING</p>
                <img src="img/students/21.png" alt="" />
            </div>
            <div className="pic-banner-div" style={{backgroundColor:"#835FF8", color:'#FFFFFF'}}>
                <div>
                    <h1>Over 1000+Topics</h1>
                    <p>BEST COURSES TO LEARN</p>
                </div>
                <div>
                    <ul>
                        <li>• Live Classes</li>
                        <li>• Dedicated Mentorship</li>
                        <li>• Regular Tests</li>
                        <li>• Personalized Education Plan</li>
                    </ul>
                </div>
                <Link to="/aboutUs">
                    <button>Know More</button>

                </Link>
            </div>
            <div className="pic-banner-div pic1-banner">
                {/* <img src="img/students/22.png" alt="" /> */}
            </div>
            <div className="pic-banner-div followup pic2-banner" style={{backgroundColor:'#F5603A', color:'#FFFFFF'}}>
                <img src="img/students/23.png" alt="" />
                <h1>Imparting Clarity</h1>
                <p>LEARN ANYWHERE, LEARN ANYTHING</p>
                <Link to="/aboutUs">
                    <button>Know More</button>

                </Link>
            </div>
        </div>
    </>
  )
}

export default Students