import React from 'react'
import "./Whyheader.css"

const Whyheader = () => {
  return (
    <div className="whyus">
        <img  className="whyus-logo" src="img/whyus/1.png" alt="" />
        {/* <div className="whyus-heading">
            <h1>How we change the lives of our students</h1>
        </div> */}
        <div className="whyus-body">
            <div className="whyus-top-header">
                <p>OUR MOTIVE OF BETTER EDUCATION</p>
                <h1>We take education to the next level that provides freedom & creativity.</h1>
            </div>
            <div className="whyus-flex">
                <div className="whyus-content">
                    <p>At RIGNSAM, we take education to the next level by providing our students with the freedom and creativity to explore their passions and reach their full potential. Our approach is centered around providing personalized learning experiences that are tailored to the unique needs of each individual student. 
                    <br/>
                    <br/>
                    Our curriculum is designed to be flexible and adaptable, allowing students to pursue their interests and passions in a way that is meaningful and impactful.</p>

                    <img src="img/whyus/2.png" alt="" />
                    <div class="whyus-bottom-head">
                        <h1><span>01</span>A Personal Course design</h1>
                    </div>
                </div>
                <div className="whyus-content">
                    <p>We specialize in providing top-notch biology preparation for NEET and CP-NET exams, with expert teachers and comprehensive curriculum. Our students receive the knowledge, skills and resources necessary to excel on these rigorous exams and achieve their academic goals.
                    <br/>
                    <br/>
                    Our teachers are always available for one-on-one tutoring and are happy to answer any questions or concerns that students may have with our dedicated mentoring support. 
                    </p>

                    <img src="img/whyus/3.png" alt="" />
                    <div class="whyus-bottom-head">
                        <h1><span>02</span>Help is anywhere you go</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Whyheader