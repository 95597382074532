import React from 'react'
import "./AffectUs.css"

const AffectUs = () => {
    return (
        <div className="affectus">
            <div className="affectus-body">
                <div className="affectus-flex-1">
                    <div className="affectus-heading">
                        <h1>We make our Students’ life better overtime</h1>
                        <p>We understand that every student is unique and therefore we offer personalized attention to each student to help them overcome their weaknesses and excel in their strengths.

                            We are committed to providing our students with the best possible chance of success in their dream career in the medical and nursing field. Our students have achieved great success and we are proud to have played a part in helping them achieve their goals.</p>
                    </div>
                    <div className="affectus-right">
                        <div className='affectus-right-body'>
                            <img src="img/whyus/7.png" alt="" />
                        </div>
                    </div>

                </div>
                <div className="affectus-body-bottom">
                    {/* <h1>Our Case Study of our Students</h1> */}
                    <div className="affectus-body-flex">
                        <ul>
                            <li>• Helping students achieve dream career in medical/nursing</li>
                            <li>• Experienced and knowledgeable teachers</li>
                        </ul>
                        <ul>
                            <li>• Personalized attention to each student</li>
                            <li>• Proven track record of student success</li>
                        </ul>
                    </div>
                    <a href="https://play.google.com/store/apps/details?id=com.kp.sir.biology.classes" target="_blank">
                        <button className='affect-btn'>Download the App</button>

                    </a>
                </div>
            </div>


        </div>
    )
}

export default AffectUs