import React,{useEffect} from 'react'
import ContactFormRig from '../AboutUS/ContactForm/ContactFormRig'
import Footer from '../landingPage/Footer/Footer'
import Navbar from '../landingPage/navbar/Navbar'
import FAQRig from './FAQ/FAQRig'
import ContactHeader from './Header/ContactHeader'

const ContactRig = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
        <Navbar/>
        <ContactHeader/>
        <FAQRig/>
        <ContactFormRig/>
        <Footer/>
    </>
  )
}

export default ContactRig