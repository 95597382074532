import React,{useState} from 'react'
import "./ContactFormRig.css"

const ContactFormRig = () => {

    const [name,setName]=useState('')
    const [email,setEmail]=useState('')
    const [cont,setCont]=useState('')
    const [desc,setDesc]=useState('')

    const handleSubmit=(e) => {
        e.preventDefault();      
        if(window.Email){
            window.Email.send({
                SecureToken : "627bd7ef-9551-49e8-b9b1-20e094589019",
                To : 'contactus@rignsam.com',
                From : "kj2409@gmail.com",
                Subject : "Rignsam",
                Body : `Dear Team, We got a response from ${name} with contact information (${email} & ${cont}) and along some description (${desc}) through our website. Please approach the client as soon as possible.`
            }).then(
                alert("We have got your response. We will get back to you soon.")
            );

        }
    }
  return (
    <div className="contact-form">
      <div className="contact-design"><img src="img/design.png" alt="" /></div>
      <div className="contact-form-div">
        <form id="form-rig">
          <h2 className="title-rig">Get In Touch</h2>
          <p>WE ARE EAGER TO KNOW YOUR THOUGHTS</p>
          <div className="input-rig">
            <input type="text" id="form__name" name="form__name" onChange={e => setName(e.target.value)} placeholder="Your Name"/>
          </div>
          <div className="input-rig">
            <input type="email" id="form__email" name="form__email" onChange={e => setEmail(e.target.value)} placeholder="Email" />
          </div>
          <div className="input-rig">
            <input type="number" id="form__phone" name="form__phone" onChange={e => setCont(e.target.value)} placeholder="Contact Number" />
          </div>
          <div className="input-rig">
            <textarea id="form__message" name="form__message" onChange={e => setDesc(e.target.value)} placeholder="Message"></textarea>
          </div>
          <div className="btn__wrapper-rig">
            <button type="submit" id="form__btn-rig" className="btn-rig" onClick={handleSubmit}>Send</button>
          </div>
        </form>

      </div>
    </div>
  )
}

export default ContactFormRig