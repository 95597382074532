import React from 'react'
import "./WhyDesc.css"

const WhyDesc = () => {
  return (
    <div className="whyus-desc">
        <div className="whyus-desc-img">
            <img src="img/whyus/4.png" alt="" />
        </div>
        <div className="whyus-desc-body">
            <h1>Learn courses at your own comfort anywhere.</h1>
            <p>At our RIGNSAM, we understand the importance of flexibility and convenience when it comes to learning. That is why we offer a wide range of online courses that allow students to learn at their own pace and on their own schedule. Our online courses are designed to provide the same level of quality and rigor as our traditional in-person classes, but with the added convenience of being able to learn from anywhere. With our online courses, you will have the freedom and flexibility to learn at your own comfort, whether it's at home, on the go, or at a location that works best for you.</p>
        </div>
    </div>
  )
}

export default WhyDesc