import React from 'react'
import 'font-awesome/css/font-awesome.min.css';
import "./Footer.css"
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className='footer'>
          <div className='footer-body'>
              <div className='footer-heading'>
                  <h1>Get hands on the <span style={{textDecoration: 'underline', cursor:'pointer'}}>Great Courses</span> you like</h1>
              </div>
              <div className='footer-description'>
                  <p>Follow us on</p>
                  <a href=""><i className="fa fa-facebook fa-lg fa-fw"></i></a>
                  <a href=""><i className="fa fa-instagram fa-lg fa-fw"></i></a>
                  <a href=""><i className="fa fa-twitter fa-lg fa-fw"></i></a>
              </div>
          </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-2">
          <div className="footer-container">
              <h1>Links</h1>
            <ul>
              <li>• <span style={{color:'white'}}><a href="https://play.google.com/store/apps/details?id=com.kp.sir.biology.classes" target="_blank">PlayStore</a></span></li>
              <li>• <span style={{color:'white'}}><Link to="/terms">Privacy Policy</Link></span></li>
              <li>• <span style={{color:'white'}}>Blogs</span></li>
            </ul>
          </div>
          <div className="footer-container">
            <h1>Company</h1>
            <ul>
              <li>• <span style={{color:'white'}}><Link to="/services">Services</Link></span></li>
              <li>• <span style={{color:'white'}}>Tutor</span></li>
              <li>• <span style={{color:'white'}}><Link to="/aboutUs">About</Link></span></li>
            </ul>
          </div>
          <div className="footer-container">
            <h1>Contact Us</h1>
            <ul>
              <li>• <span style={{color:'white'}}>Kalikapur, Kolkata 700099</span></li>
              <li>• <span style={{color:'white'}}>Phone: 8210475883 / 8578980746</span></li>
              <li>• <span style={{color:'white'}}>Email: contactus@rignsam.com</span></li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom-down">
          <p className='footer-para'>Copyright - © Rignsam Edtech Pvt Ltd - 2023</p>
          <a href="https://slogan.media/" target="_blank"><img src="img/footer.png" alt="" /></a>
        </div>
      </div>
    </>
  )
}

export default Footer