import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import "./Navbar.css"


const Navbar = () => {

  const [click, setClick] = React.useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);

  return (
    <div className={`header-top`}>
      <div className={click ? "main-container" : ""} onClick={() => Close()} />
      <nav className={`navbar`} onClick={e => e.stopPropagation()}>
        <div className={`nav-container`}>
          <NavLink exact to="/" className="nav-logo">
            <img src="img/logo.png" alt="" />
          </NavLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/aboutUs"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                About&nbsp;Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/services"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/whyUs"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Why&nbsp;Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contactUs"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Contact&nbsp;Us
              </NavLink>
            </li>
            {/* <li className="nav-item"> */}
            {/* </li> */}
            <a href="https://play.google.com/store/apps/details?id=com.kp.sir.biology.classes" target="_blank">
              <button className="nav-btn">Download The App</button>

            </a>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
        </div>
      </nav>
    </ div>
    // <div>
    //     <nav className="navbar">
    //         <div className="navbar-container container">
    //             <input type="checkbox" name="" id=""/>
    //                 <div className="hamburger-lines">
    //                     <span className="line line1"></span>
    //                     <span className="line line2"></span>
    //                     <span className="line line3"></span>
    //                 </div>
    //                 <div className="logo"><img src="img/logo.png" alt="" /></div>
    //                 <ul className="menu-items">
    //                     <li><Link to="/">Home</Link></li>
    //                     <li><Link to="/about">About Us</Link></li>
    //                     <li><Link to="/service">Services</Link></li>
    //                     <li><Link to="/whyUs">Why Us</Link></li>
    //                     <li><Link to="/contact">Contact</Link></li>
    //                     <button className="navbar-btn">Download the App</button>
    //                 </ul>
    //         </div>
    //     </nav>
    // </div>
  )
}

export default Navbar