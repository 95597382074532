import React from 'react'
import "./WhyVi.css"

const WhyVi = () => {
  return (
    <div className='whyvi'>
        <div className='whyvi-head-img'>
          <img src="img/whyus/6.png" alt="" />
        </div>
        <div className='whyvi-body'>
          <div className='whyvi-heading'>
            <h1>Our Workflow Overview</h1>
            <h3>OUR MOTIVE OF BETTER EDUCATION</h3>
            <p>Transforming lives of our students through the power of education by providing personalized and dedicated learning experiences.</p>
          </div>
          <div className="whyvi-video">
            <img src="img/whyus/5.png" alt="" />
          </div>
          <div className="whyvi-flex">
            <div className="whyvi-content">
              <img src="img/whyus/video/1.png" alt="" />
              <h1><span>01</span>Download App</h1>
              <p>Download <span style={{color:'#1BD697'}}>KP Sir’s Biology Classes</span> App from PlayStore</p>
            </div>
            <div className="whyvi-content">
              <img src="img/whyus/video/2.png" alt="" />
              <h1><span>02</span>Enroll for course</h1>
              <p>Choose best course which fits your goal</p>
            </div>
            <div className="whyvi-content">
              <img src="img/whyus/video/3.png" alt="" />
              <h1><span>03</span>Learn Anytime</h1>
              <p>Learn anytime, anywhere with recorded classes</p>
            </div>
          </div>

        </div>
    </div>
  )
}

export default WhyVi