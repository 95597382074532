import React from 'react'
import "./WhyRan.css"

const WhyRan = () => {
  return (
    <div className="whyrig">
        <div className="whyrig-heading">
            <h1>Why <span style={{color: 'red'}}>RIG</span><span style={{color: '#10CED4'}}>N</span><span style={{color: '#8C52FF'}}>SAM</span></h1>
        </div>
        <div className='whyrig-div'>
            <img src="img/whyrig/1.png" alt="" />
            <div className="whyrig-content">
                <h1>Live interactive Lectures</h1>
                <p>At RIGNSAM, we believe that learning is a crucial aspect of reaching one's goals and aspirations. Instead of simply memorizing information, we aim to foster a deeper understanding of concepts. Our live lectures provide a platform for us to understand the unique needs of each student, and to inspire them to think creatively and approach their studies with passion.</p>
            </div>
        </div>
        <div className='whyrig-div rig-rev'>
            <img src="img/whyrig/2.png" alt="" />
            <div className="whyrig-content">
                <h1>Live Doubt Solving Sessions</h1>
                <p>At RIGNSAM, we strongly promote an inquisitive mindset among our students, fostering an environment where they feel comfortable raising any questions or doubts they may have. We firmly believe that the more one asks, the more one can learn, and strive to create an atmosphere that encourages this.</p>
            </div>
        </div>
        <div className='whyrig-div'>
            <img src="img/whyrig/3.png" alt="" />
            <div className="whyrig-content">
                <h1>Structured Study Material</h1>
                <p>Our structured study material is designed to provide students with a clear and organized learning experience. The material is broken down into manageable sections and includes a variety of resources such as videos, quizzes, and reading materials to cater to different learning styles Our structured study material is a valuable tool for students who want to achieve their goals and excel in their studies.</p>
            </div>
        </div>
        <div className='whyrig-div rig-rev'>
            <img src="img/whyrig/4.png" alt="" />
            <div className="whyrig-content">
                <h1>Regular Mock Tests for progress tracking</h1>
                <p>Regular mock tests are an important tool for tracking progress and improving performance. Our mock tests are designed to simulate the real exam experience, giving students an idea of what to expect on test day. They will also help them to build confidence and readiness for the final exams.</p>
            </div>
        </div>
        <div className='whyrig-div'>
            <img src="img/whyrig/5.png" alt="" />
            <div className="whyrig-content">
                <h1>Recorded Video Lessons</h1>
                <p>Recorded video lessons provide students with the flexibility to learn at their own pace and on their own schedule. The videos are recorded by experienced educators and cover the material in a clear and concise manner. The videos are also supplemented with additional materials such as reading materials, quizzes, and homework assignments to enhance the learning experience.</p>
            </div>
        </div>
    </div>
  )
}

export default WhyRan