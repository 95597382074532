import React, { useState } from 'react'
import "./PopUI.css"

const PopUi = ({ close, word }) => {
    const [name,setName]=useState('')
    const [email,setEmail]=useState('')
    const [cont,setCont]=useState('')
    const [desc,setDesc]=useState('')

    const handleSubmit=(e) => {
        e.preventDefault();      
        if(window.Email){
            window.Email.send({
                SecureToken : "627bd7ef-9551-49e8-b9b1-20e094589019",
                To : 'contactus@rignsam.com',
                From : "kj2409@gmail.com",
                Subject : "Rignsam",
                Body : `Dear Team, We got a response from ${name} with contact information (${email} & ${cont}) in Class (${desc}) through our website. Please approach the client as soon as possible.`
            }).then(
                alert("We have got your response. We will get back to you soon.")
            );

        }
    }
    return (
        <>

            <div className="contact-form-new">
                <div className="contact-form-div new-contact-form-div">
                    <button className="close" onClick={close}>
                        &times;
                    </button>
                    <form id="form-rig">
                        <h2 className="title-rig">Book your spot for Offline Classes</h2>
                        <div className="input-rig">
                            <input type="text" id="form__name" name="form__name" onChange={e => setName(e.target.value)} placeholder="Student Name" />
                        </div>
                        <div className="input-rig">
                            <input type="email" id="form__email" name="form__email" onChange={e => setEmail(e.target.value)} placeholder="Email" />
                        </div>
                        <div className="input-rig">
                            <input type="number" id="form__phone" name="form__phone" onChange={e => setCont(e.target.value)} placeholder="Contact Number" />
                        </div>
                        <div className="input-rig">
                            <input type="text" id="class" name="class" onChange={e => setDesc(e.target.value)} placeholder="Enter Class (IX,X,XI,XII,Dropper)" />
                        </div>
                        {/* <div className="input-rig new-spot-rig">
                            <label htmlFor="class">Class</label>
                            <select name="class" id="class" onChange="this.form.submit()">
                                <option value="1">IX</option>
                                <option value="2">X</option>
                                <option value="3">XI</option>
                                <option value="4">XII</option>
                                <option value="5">Dropper</option>
                            </select>
                        </div> */}
                        <div className="btn__wrapper-rig">
                            <button type="submit" id="form__btn-rig" className="btn-rig" onClick={handleSubmit}>Book</button>

                        </div>
                    </form>

                </div>
            </div>


        </>
    )
}

export default PopUi