import React,{useEffect} from 'react'
import ContactFormRig from '../AboutUS/ContactForm/ContactFormRig'
import Footer from '../landingPage/Footer/Footer'
import Navbar from '../landingPage/navbar/Navbar'
import "./WhyServices.css"

const WhyServices = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
        <Navbar/>
        <div className='whyservices'>
            <div className='whyservices-heading'>
                <h1>Our Pillars</h1>
            </div>
            <div className='whyservices-head-top'>
                <div className='whyservices-div-head'>
                    <h1>Services (Test Preparatory)</h1>
                </div>
                <div className='whyservices-flex new-whyservices-flex'>
                    <div className='whyservices-content'>
                        <h1>NEET</h1>
                        <p>We provide comprehensive preparation for students aiming to excel in the NEET exams. Our resources, instruction, and activities help students perform at their best on the exam.</p>
                    </div>
                    <div className='whyservices-content'>
                        <h1>CP NET</h1>
                        <p>RIGNSAM specializes in providing comprehensive preparation for students who are looking to excel in the CP-NET exams. We offer a variety of resources and study materials, as well as classroom instruction.</p>
                    </div>
                    <div className='whyservices-content'>
                        <h1>IIT</h1>
                        <p>We focus on helping students achieve their best results in the IIT exams. We provide a range of resources, including study materials, interactive learning opportunities, and practice tests to ensure students are well-prepared for the exams.</p>
                    </div>
                </div>
                <div className='whyservices-flex new-whyservices-flex'>
                    <div className='whyservices-content'>
                        <h1>Nursing Entrance</h1>
                        <p>Nursing entrance exams can be tough, but with the right preparation, success is within reach. RIGNSAM offers specialized preparation for students looking to excel in these exams.</p>
                    </div>
                    <div className='whyservices-content'>
                        <h1>Board Exams</h1>
                        <p>We offer specialized preparation for students looking to excel in any Board Exams. With a focus on providing comprehensive instruction, interactive activities and ample resources.</p>
                    </div>
                    <div className='whyservices-content'>
                        <h1>Other Exams</h1>
                        <p>We also help prepare our students other competitive exams. From classroom instruction, interactive activities and resources, to personalized support from our experienced teachers, we ensure students have the best chance of success.</p>
                    </div>
                </div>
            </div>
            <img className="whyservices-img" src="img/design2.png" alt="" />
            <div className='whyservices-head-top'>
                <div className='whyservices-div-head'>
                    <h1>Solutions</h1>
                </div>
                <div className='whyservices-flex new-whyservices-flex'>
                    <div className='whyservices-content'>
                        <h1>Admission Counseling</h1>
                        <p>Our experienced counselors guide students through the application process, providing them with researching colleges, completing applications, and preparing for interviews advice and support they need to make informed decisions.</p>
                    </div>
                    <div className='whyservices-content'>
                        <h1>Placement Support</h1>
                        <p>We offer comprehensive placement support to our students to help them secure their dream job. We provide career counseling, resume building and interview skills training.</p>
                    </div>
                    <div className='whyservices-content'>
                        <h1>Corporate Training & Certifications</h1>
                        <p>We provides corporate training and certification services to help companies and organizations develop and enhance their employees' skills and knowledge. We offer a wide range of training programs and certifications, tailored to meet the specific needs of each organization.</p>
                    </div>
                </div>
            </div>
            <img className="whyservices-img" src="img/design1.png" alt="" />
            <div className='whyservices-head-top'>
                <div className='whyservices-div-head'>
                    <h1>Enterprise</h1>
                </div>
                <div className='whyservices-flex'>
                    <div className='whyservices-content'>
                        <h1>SkillIndia Development program</h1>
                        <p>We assist our students with skill development programs and training to create a career in collaboration with Govt. of India’s ‘Skill India’ Program.    </p>
                    </div>
                    <div className='whyservices-content'>
                        <h1>Pro-metrics & Examinations </h1>
                        <p>We provide a wide range of assessment tools, including aptitude tests, personality tests, and skill assessments, to help organizations understand the strengths and weaknesses of their employees and students.</p>
                    </div>
                </div>
            </div>
            <div className='whyservices-head-top'>
                <div className='whyservices-div-head'>
                    <h1>Consulting</h1>
                </div>
                <div className='whyservices-flex'>
                    <div className='whyservices-content'>
                        <h1>Project Management</h1>
                        <p>We offer professional project management services to help organizations plan, execute and deliver projects on time and within budget. </p>
                    </div>
                    <div className='whyservices-content'>
                        <h1>Accreditation</h1>
                        <p>We assist organizations in preparing for accreditation assessments, by providing guidance on best practices and standards, as well as conducting gap analysis and readiness assessments.</p>
                    </div>
                </div>
            </div>
            
        </div>
        <ContactFormRig/>
        <Footer/>
    </>
  )
}

export default WhyServices