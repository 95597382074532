import React,{useEffect} from 'react'
import Footer from '../landingPage/Footer/Footer'
import Navbar from '../landingPage/navbar/Navbar'
import ContactFormRig from './ContactForm/ContactFormRig'
import ContactForm from './ContactForm/ContactFormRig'
import Header from './Header/Header'

const AboutUS = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
        <Navbar/>
        <Header/>
        <ContactFormRig/>
        <Footer/>
    </>
  )
}

export default AboutUS