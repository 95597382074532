import React from 'react'
import "./FAQRig.css"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

const FAQRig = () => {

    return (
        <div className="FAQ-rig-div">
            <div className="FAQ-rig">
                <h1>FAQ</h1>
            </div>
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How can I download the app?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            To download the app, simply go to the Google Play on your mobile device and search for ‘KP Sir’s Biology Classes’. Once you have found the app, simply click on the "Download" or "Install" button to start the process.


                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Is the app free to download?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Yes, the app is completely free to download.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Are there any additional costs associated with using the app?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            The resources and study materials in the app come to you at the most affordable prices.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Which class of students is this app suitable for?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Our app is designed for students who are studying for pre medical entrance exams such as NEET and nursing entrance exams such as CP NET. It is intended for students in XIth and XIIth who are preparing for these exams and want to achieve their dream career in the medical field.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

export default FAQRig