import React from 'react'
import "./Testimonial.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


const Testimonial = () => {

    return (
        <div className="testimonial">
            <div className="testimonial-heading">
                <h1>Testimonials</h1>
            </div>

            <Carousel className="owl-carousel" autoPlay={true} interval="5000" infiniteLoop={true}>
                <div className="testimonial-body">
                    <div className="testimonial-img">
                        <img src="img/studing.png" alt="" />
                    </div>
                    <div className="testimonial-content">      
                        <img src="img/quote.png" alt="" />
                        <p>I had the privilege of having K P Sir as my Biology teacher for XIth and XIIth and I can confidently say that he is a very good and thoughtful teacher. He has the unique ability to explain even the most complex topics with complete clarity, making it easy for me to understand and retain the information. I would highly recommend K P Sir’s Biology Classes to anyone studying Biology in XIth and XIIth, as he truly is an exceptional educator.</p>

                        <div className='testimonial-content-para'>
                            <h1>Student</h1>
                            <p>Class XI, B.D.M International School</p>
                        </div>

                    </div>
                </div>
                <div className="testimonial-body">
                    <div className="testimonial-img">
                        <img src="img/studing1.png" alt="" />
                    </div>
                    <div className="testimonial-content">
                        <img src="img/quote.png" alt="" />
                        <p>I have had the pleasure of taking classes with Sir, and I can confidently say that his teaching method is unparalleled. He has the unique ability to break down complex concepts into easily understandable terms. Additionally, the abundance of MCQs he provides helps to solidify the material and ensure that I have a strong grasp on the subject matter.</p>

                        <div className='testimonial-content-para'>
                            <h1>Student</h1>
                            <p>Class XII, DPS, Ruby park</p>
                        </div>

                    </div>
                </div>
                <div className="testimonial-body">
                    <div className="testimonial-img">
                        <img src="img/studing2.png" alt="" />
                    </div>
                    <div className="testimonial-content">
                        <img src="img/quote.png" alt="" />
                        <p>If you are looking for the best Biology teacher for NEET examinations, look no further than KP Sir. He has a wealth of knowledge and experience in the subject and is able to convey it in a way that is easily understandable and relatable. His teaching methods are tailored to prepare students for the rigors of the NEET exams and I can say with certainty that he is the best ever Biology teacher for the NEET examinations."</p>

                        <div className='testimonial-content-para'>
                            <h1>Student</h1>
                            <p>Class XI, Indus Valley World School</p>
                        </div>

                    </div>
                </div>
            </Carousel>
        </div>
    )
}

export default Testimonial