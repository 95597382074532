import logo from './logo.svg';
import './App.css';
import Landing from './components/landingPage/Landing';
import AboutUS from './components/AboutUS/AboutUS'
import ContactRig from './components/ContactUs/ContactRig'
import WhyServices from './components/Services/WhyServices'
import WhyUs from './components/WhyUs/WhyUs'
import {
  BrowserRouter as Router,
  Routes ,Route
} from "react-router-dom";
import Terms from './components/Terms';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Landing/>}/>
        <Route exact path="/aboutUs" element={<AboutUS/>}/>
        <Route exact path="/services" element={<WhyServices/>}/>
        <Route exact path="/whyUs" element={<WhyUs/>}/>
        <Route exact path="/contactUs" element={<ContactRig/>}/>
        <Route exact path="/terms" element={<Terms/>}/>
      </Routes>
    </Router>
  );
}

export default App;
