import React,{useEffect} from 'react'

import About from './about/About'
import HeroBanner from './banner/HeroBanner'
import Footer from './Footer/Footer'
import Navbar from './navbar/Navbar'
import Services from './Services/Services'
import Students from './students/Students'
import TextBanner from './Test-banner/TextBanner'
import Testimonial from './testimonial/Testimonial'
import WhyRan from './WhyRan/WhyRan'

const Landing = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
        <Navbar/>
        <HeroBanner/>
        <TextBanner/>
        <Services/>
        <About/>
        <WhyRan/>
        <Testimonial/>
        <Students/>
        <Footer/>
        
    </>
  )
}

export default Landing