import React from 'react'
import "./TextBanner.css"

const TextBanner = () => {
  return (
    <div className="TextBanner">
      <img src="img/textBanner.png" alt="" />
      <div className="TextBanner-content">
          <div className="TextBanner-heading">
            <h1>India’s #1 Biology Preparation for NEET Aspirants</h1>
          </div>
          <div className="TextBanner-body">
            <div className="TextBanner-div">
              <h1>1K+</h1>
              <p>Students</p>
            </div>
            <div className="TextBanner-div">
              <h1>100+</h1>
              <p>Video Lectures</p>
            </div>
            <div className="TextBanner-div">
              <h1>50+</h1>
              <p>Mock Test</p>
            </div>
          </div>
      </div>
    </div>
  )
}

export default TextBanner