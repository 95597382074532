import React from 'react'
import "./About.css"

const About = () => {
  return (
    <div className="about-landing">
        <div className="about-flex">
            <div className="about-content">
                <img src="img/aboutIcons/1.png" alt="" />
                <h1>Regular live classes</h1>
                <p>Experience interactive and collaborative learning with our regular live classes, providing the opportunity to connect with peers and instructors in real-time.</p>
            </div>
            <div className="about-content">
                <img src="img/aboutIcons/2.png" alt="" />
                <h1>Practice and revise</h1>
                <p>Excel in your studies with our practice and revise resources, designed to help you master the material and achieve your goal.</p>
            </div>
            <div className="about-content">
                <img src="img/aboutIcons/3.png" alt="" />
                <h1>Learn anytime, anywhere </h1>
                <p>Chat with educators, ask questions, answer live polls, and get your doubts cleared - all while the class is going on from anywhere with our App.</p>
            </div>
        </div>
    </div>
  )
}

export default About