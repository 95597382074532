import React from 'react'
import "./Services.css"

const Services = () => {
  return (
    <div className="services">
        <div className="services-heading"><h1>Course Highlights</h1></div>
        <div className="services-body">
            <div className="services-flex">
                <div className="services-content serv-1">
                    <img src="img/serviceIcons/1.png" alt="" />
                    <div className="services-content-div">
                        <h1>Certified Courses</h1>
                        <p>Certified courses taught by experienced professionals and designed to provide the skills and knowledge you need for success.</p>
                    </div>
                </div>
                <div className="services-content serv-2">
                    <img src="img/serviceIcons/4.png" alt="" />
                    <div className="services-content-div">
                        <h1>Mock Tests</h1>
                        <p>Mock tests designed to simulate the real exam experience and help you identify areas for improvement.</p>
                    </div>
                </div>
            
            </div>
            <div className="services-flex">
                <div className="services-content serv-3">
                    <img src="img/serviceIcons/2.png" alt="" />
                    <div className="services-content-div">
                        <h1>Video Coachings</h1>
                        <p>Flexible and engaging learning experience with our video lessons, available anytime and anywhere to fit your schedule.</p>
                    </div>
                </div>
                <div className="services-content serv-4">
                    <img src="img/serviceIcons/5.png" alt="" />
                    <div className="services-content-div">
                        <h1>App Support</h1>
                        <p>App support, offering convenient access to course materials, progress tracking, and communication with educators.</p>
                    </div>
                </div>
            
            </div>
            <div className="services-flex">
                <div className="services-content serv-5">
                    <img src="img/serviceIcons/3.png" alt="" />
                    <div className="services-content-div">
                        <h1>Dedicated Mentorship</h1>
                        <p>Dedicated mentorship program, offering personalized guidance and support from experienced faculties.</p>
                    </div>
                </div>
                <div className="services-content serv-6">
                    <img src="img/serviceIcons/6.png" alt="" />
                    <div className="services-content-div">
                        <h1>World Class Educators</h1>
                        <p>Achieve excellence with our world-class educators, dedicated to provide personalized instruction and guidance.</p>
                    </div>
                </div>
            
            </div>
        </div>
    </div>
  )
}

export default Services