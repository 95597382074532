import React from 'react'
import "./HeroBanner.css"
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import PopUi from './PopUI';

const HeroBanner = () => {
    return (
        <div className="hero-banner-header">
            <div className="hero-banner-img">
                <img src="img/banner1.png" alt="" />
                <div className='hero-pop'>
                    <Popup
                        trigger={<button className="book-spot">Book Your Spot</button>}
                        modal
                        nested
                    >
                        {close => (
                            <PopUi close={close} />
                        )}
                    </Popup>
                </div>
            </div>

            <div className="hero-banner">
                <div className="hero-banner-content">
                    <h1>BEST APP FOR ONLINE BIOLOGY CLASSES</h1>
                    <div className="hero-banner-div">
                        <div className="hero-banner-button">
                            <a href="https://play.google.com/store/apps/details?id=com.kp.sir.biology.classes" target="_blank">
                                <button>DOWNLOAD FOR FREE</button>
                            </a>
                            <div className="hero-banner-qr">
                                <img src="img/qr-code.png" alt="" />
                                <p>Scan to download</p>
                            </div>
                        </div>
                        <div className="hero-banner-gplay">
                            <img src="img/gplay.png" alt="" />
                            <div className="hero-banner-gplay-para">
                                <p>Step 1 : Go to Play Store</p>
                                <p>Step 2 : Search for <span style={{ color: "green" }}>‘KP Sir’s Biology Classes’</span></p>
                                <p>Step 3 : Download the App</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='hero-boy'>
                    <img src="img/boy1.png" alt="" />
                </div>
                
            </div>
        </div>
    )
}

export default HeroBanner