import React from 'react'
import "./Header.css"

const Header = () => {
  return (
    <div className="header-about">
        <img src="img/aboutus/top.png" alt="" />
        <div className="header-div">
            <div className="header-heading">
                <h1>About Us</h1>
            </div>
            <div className="header-body">
                <div className="header-flex">
                    <img src="img/aboutus/1.png" alt="" />
                    <h1>Our Mission</h1>
                    <p>To empower students to reach their full potential through transformative learning experiences.</p>
                </div>
                <div className="header-flex">
                    <img src="img/aboutus/2.png" alt="" />
                    <h1>Our Courses</h1>
                    <p>Ace your NEET exam with our comprehensive study materials, expert instructors, and proven test-taking strategies.</p>
                </div>
                <div className="header-flex">
                    <img src="img/aboutus/3.png" alt="" />
                    <h1>Our faculty</h1>
                    <p>Experience the best in class education with our highly qualified and dedicated faculty who are experts in their field and passionate about teaching.</p>
                </div>
            </div>
            <div className="header-para">
                <div className="header-para-heading">
                    <h1>The Founders</h1>
                </div>
                <div className="header-para-content">
                    <div className="header-para-div1">
                        <img src="img/aboutus/4.png" alt="" />
                        <h1>Krishna Prem Jha</h1>
                        <p>Founder and CEO</p>
                    </div>
                    <div className="header-para-div2">
                        <p><span>Krishna</span> is a highly skilled and experienced professional with a wealth of knowledge and expertise in a variety of fields. He brings a bouquet of experience to the table, having worked in the fields of teaching, healthcare operations, learning and development, and consulting. He is a true believer in the power of intuitive learning, and has been able to make significant impacts in the lives of his students and peers throughout his career.
                        <br/>
                        <br/>
                        As a teacher, Krishna has consistently been a favorite among both his peers and his students. He has taught at nursing colleges and coaching centers, and his students have consistently praised his ability to make complex concepts easy to understand. He has a unique ability to build a strong foundation of knowledge in his students, which allows them to better understand and retain the material. His dedication to ensuring that his students have a deep understanding of the material is evident in the way he teaches, and he is always willing to go the extra mile to help his students succeed.</p>
                    </div>
                </div>
                <div className="header-para-div3">
                    <p>Krishna has also been involved in learning and development and consulting. He has helped organizations improve their training and development programs, and he has also provided expert advice and guidance to help organizations reach their goals. He is known for his ability to think outside of the box and for his ability to find creative solutions to complex problems.
                    <br/>
                    <br/>
                    Krishna is passionate about fostering curiosity and sparking a love for learning in his students. He believes that the spark of curiosity is what drives human evolution and that it is the duty of educators to inspire and nurture that spark in all of their students. He is always seeking new ways to engage and inspire his students, and he is dedicated to helping them reach their full potential. He often says "The Sparkle that follows a curiosity, when it does, has been the wheels of human evolution, and it’s our job to elicit that sparkle in every of our students”.</p>
                </div>
                <div className="header-para-content flex-content">
                    <div className="header-para-div1">
                        <img src="img/aboutus/5.png" alt="" />
                        <h1>Manish Kumar Jha</h1>
                        <p>Founder and COO</p>
                    </div>
                    <div className="header-para-div2">
                        <p><span>Manish</span> has an extensive background in managing Pradhan Mantri Kaushal Kendra Skill Development centers in Ranchi, Jharkhand and Saharsa, Bihar. He has a proven track record of success in this role, and has been responsible for ensuring that the highest quality training services are provided to students. During his tenure as Center Manager, he has trained more than 3500 students, and has been responsible for overseeing all aspects of the center's operations, including training, assessments, certifications, placements, and overall performance.
                        <br/>
                        <br/>
                        Manish is a highly experienced teacher and trainer, having taught a wide range of subjects including soft skills, vocational subjects, and other areas of study. He has a wealth of knowledge and expertise in both theoretical and practical training methods, and is known for his ability to effectively convey complex concepts to students. He has also provided consultations to students, which has helped them to achieve their goals.</p>
                    </div>
                </div>
                <div className="header-para-div3">
                    <p>Manish has also established himself as an expert in organizing placement drives and student counseling. He has a deep understanding of the job market and is well-versed in the skills and qualifications that employers are looking for. He has helped many students secure employment after completing their training, and is dedicated to helping students succeed in their careers.
                    <br/>
                    {/* <br/>
                    In conclusion, Manish is a highly skilled and experienced professional with a proven track record of success in managing Pradhan Mantri Kaushal Kendra Skill Development centers. He is an expert in teaching, training and counseling, and has a wealth of knowledge and expertise in various subjects. He has helped more than 3500 students to achieve their goals and has a great expertise in organizing placements drives and student counseling. He is a dedicated and highly respected professional who is committed to helping students succeed in their careers. */}
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Header